import './App.css';
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Contato from './pages/Contato';
import Dashboard from './pages/Dashboard';
import Navbar from './components/Navbar';
import Container from './layout/Container';
import Footer from './layout/Footer';
import Contact from './components/contact';
import Register from './pages/Register';
import AppDash from './pages/AppDash';
import SimulatePayment from './pages/SimulatePayment';
import PaymentPIX from './pages/PaymentPIX';
import ListTransations from './pages/ListTransations';
import CheckoutPage from './pages/CheckoutPage';


function AppContent() {
  const location = useLocation();
  const isDashboard = location.pathname === '/login';
  const isRegister = location.pathname === '/register';
  const isApp = location.pathname === '/app';
  const isSimulatePayment = location.pathname === '/simulatepayment';
  const isPaymentPIX = location.pathname === '/pagar-pix';
  const isListTransaction = location.pathname === '/list-transations'
  // const isCheckout = location.pathname === '/checkout';

  // Condição para mostrar Navbar e Contact
  const showNavbarAndContact = !isDashboard && !isRegister && !isApp && !isSimulatePayment && !isPaymentPIX  && !isListTransaction && location.pathname !== '/checkout';

  return (
    <div className={`App ${isDashboard ? 'dashboardBackground' : ''}`}>
      {showNavbarAndContact && <Contact />}
      {showNavbarAndContact && <Navbar />}
      <Container customClass="min-height">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/login">
            <Dashboard />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/app">
            <AppDash />
          </Route>
          <Route path="/contato">
            <Contato />
          </Route>
          <Route path="/termos.pdf">
            <Redirect to="/termos.pdf" />
          </Route>
          <Route path="/simulatepayment">
            <SimulatePayment />
          </Route>
          <Route path="/pagar-pix">
            <PaymentPIX />
          </Route>
          <Route path="/list-transations">
            <ListTransations />
          </Route>
          <Route path="/checkout">
            <CheckoutPage />
          </Route>
        </Switch>
      </Container>
      {showNavbarAndContact && <Footer />}
    </div>
  );
}



function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
