import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ModalComponent from './ModalSimulatePayment';
import styles from './SimulatePayment.module.css';
import Sidebar from './Sidebar';

const FormFaturas = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [valor, setValor] = useState('');
  const [billType, setBillType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setErrorMessage('');
  };

  const isPixCode = (code) => {
    return code.length === 32;
  };

  const handleSimular = () => {
    const unformattedValor = valor.replace(/\D/g, ''); // Remove a formatação antes de enviar

    if (isPixCode(unformattedValor)) {
      setErrorMessage('Código PIX detectado! Por favor, insira um código de barras de boleto.');
      return;
    }

    setBillType(unformattedValor);
    openModal(unformattedValor);
  };

  const formatBarcode = (code) => {
    const onlyNumbers = code.replace(/\D/g, '');
    const parts = [
      onlyNumbers.slice(0, 5),  // Primeiros 5 dígitos
      onlyNumbers.slice(5, 10), // Próximos 5 dígitos
      onlyNumbers.slice(10, 15), // Próximos 5 dígitos
      onlyNumbers.slice(15, 21), // Próximos 6 dígitos
      onlyNumbers.slice(21, 26), // Próximos 5 dígitos
      onlyNumbers.slice(26, 32), // Últimos 6 dígitos (parte 1)
      onlyNumbers.slice(32, 33), // Último dígito (parte 2)
      onlyNumbers.slice(33)      // Restante
    ].filter(part => part.length > 0);
  
    return parts.join(' ');
  };
  
  const handleChange = (e) => {
    setValor(formatBarcode(e.target.value));
  };
  

  useEffect(() => {
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');

    if (!storedCpfCnpj) {
      history.push('/login');
    } else {
      history.push('/simulatepayment');
    }
  }, [history]);

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar />
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                <h2>Simule os seus pagamentos com a CarinaPaga!<br /></h2>
                <p>
                  Com a CarinaPaga, você pode simular diferentes opções de pagamento para suas faturas e despesas. Nossa plataforma oferece ferramentas intuitivas para que você possa visualizar e comparar alternativas de parcelamento e quitação, proporcionando uma visão clara e prática do impacto financeiro de suas escolhas. Experimente nossa simulação para encontrar a solução que melhor se adapta ao seu orçamento e aproveite uma gestão de pagamentos mais eficiente e personalizada.
                </p>
                <br />

                <div className={styles.simulationCard}>
                  <h2>Insira aqui o código de barras do seu boleto/faturas</h2>
                  <div className={styles.input} style={{ marginLeft: '12em' }}>
                    <input
                      type="text"
                      id="barcode"
                      placeholder="Código de Barras"
                      className={styles.input}
                      value={valor}
                      onChange={handleChange}
                    />
                  </div>
                  <button onClick={handleSimular}>Simular</button>
                  {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  <p>Seu boleto/fatura será pesquisado em nosso sistema em alguns instantes após o clique no botão.</p>
                  <p style={{ marginTop: '-20px' }}>Consulte as taxas de serviço da CarinaPaga:<br></br><a href='https://www.carinapaga.com' className={styles.registerLink}>Taxas & Encargos</a></p>
                </div>
                <ModalComponent 
                  isOpen={modalIsOpen} 
                  closeModal={closeModal} 
                  billType={billType}
                  billType2={valor}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FormFaturas;
